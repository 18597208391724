import React, { useEffect, useRef, useState } from 'react'
import Link from 'gatsby-link'
import { Parallax } from 'react-parallax'

import './home.scss'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'
import FeaturedItems from 'components/Home/Featured/FeaturedItems'
import FeaturedCameraItems from 'components/Home/Featured/FeaturedCameraItems'
import Review from 'components/shared/Reviews/Review'
import sitting_boy_bg from '../../static/img/home/background.png'
import halfBike_bg from '../../static/img/home/bike_background.png'
import Navbar from 'components/Navbar'
import camera_bg from '../../static/img/home/camera-bg.jpg'
import CategorySlider from 'components/Home/CategorySlider/CategorySlider'
import ScrollIndicator from 'components/ScrollIndicator/ScrollIndicator'
import { useNavbarConfig } from 'components/Navbar/NavbarConfig'
import StickySentinal from 'components/Navbar/StickySentinal'
import { searchStore } from '../SearchStore'

function IndexPage(props) {
  const [isPsuedoNavbarStickied, setPsuedoNavbarStickied] = useState(false)
  useEffect(() => {
    // reset the search store on mount
    searchStore.reset()
  }, [])
  return (
    <div id="home-page">
      <Parallax
        blur={{ min: -20, max: 1 }}
        bgImage={sitting_boy_bg}
        bgImageStyle={{
          bottom: '-200px',
        }}
        strength={400}
      >
        <section className="front-welcome-parallax">
          <div className="intro-section">
            <svg
              className="home-cloud home-cloud-1"
              xmlns="http://www.w3.org/2000/svg"
              width="1360"
              height="958"
              viewBox="0 0 1360 958"
            >
              <path
                fillRule="evenodd"
                d="M856.032,43.233 C763.56,51.354 755.995,47.037 702.004,24.653 C665.707,8.809 625.635,0 583.5,0 C457.462,0 349.808,78.649 306.897,189.541 C272.98,271.849 260.919,283.503 181.846,316.876 C75.359,358.829 0,462.613 0,584 C0,742.506 128.494,871 287,871 C328.535,871 368.001,862.164 403.643,846.289 C491.057,809.571 522.617,802.872 651.485,883.891 C723.395,930.758 809.265,958 901.5,958 C1154.723,958 1360,752.723 1360,499.5 C1360,246.277 1154.723,41 901.5,41 C886.155,41 870.989,41.76 856.032,43.233 Z"
                opacity=".2"
              />
            </svg>
          </div>
        </section>
      </Parallax>
      <section id="main" className="front-welcome">
        <div className="intro-section">
          <div className="header-row">
            <div className="intro-header-container">
              <h1 className="intro-header">BORROW FROM LOCALS</h1>
            </div>
          </div>
        </div>
        <StickySentinal
          onStickyChange={isStuck => setPsuedoNavbarStickied(isStuck)}
        />
        {/* A sticky sentinal */}
        <Navbar
          disableStore={true}
          style={{ zIndex: 999999 }}
          overlay={true}
          scrollEffects={false}
          hideLeft={true}
          showSearchBar={true}
          hideRight={true}
          searchBarProps={{
            forceFocus: !isPsuedoNavbarStickied,
          }}
        />
        {/* <div ref={intersectionTarget} style={{width:'100vw', height : 120, background : 'blue'}}/> */}
        <ScrollIndicator
          style={{ alignSelf: 'center', padding: '10px 20px', margin: '20px' }}
          scrollTarget={'#home-page'}
          scrollTo={'#category-slider'}
        />
      </section>

      <CategorySlider id={'category-slider'} />

      <section className="container page-section" id="featured-section">
        <div className="row">
          <FeaturedItems />
        </div>
      </section>

      <section className="page-section" id="how-it-works-section">
        <div className="container">
          <div className="row how-it-works-row">
            <h1>How it works</h1>
            <div className="col-md-4 home-col">
              <h3>Browse & Book</h3>
              <p>
                Discover items you need from bikes to boosted boards - pickup is
                local and on your time.
              </p>
            </div>
            <div className="col-md-4 home-col">
              <h3>Play & Pay</h3>
              <p>
                Have fun with your rental for as long as you need. Secure
                payments are done in one easy click.
              </p>
            </div>
            <div className="col-md-4 home-col">
              <h3>Return & Review</h3>
              <p>
                After you're done, dropoff the item with the lender and give a
                review of your experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="product-section">
        <div className="container">
          <div className="row">
            <h1 style={{ marginBottom: 20 }}>Recent Reviews</h1>
            <div className="row review-row">
              <div className="col-md-4">
                <Review
                  className="home-review-item"
                  name="Arthur"
                  city="Washington, D.C."
                  img="https://randomuser.me/api/portraits/men/6.jpg"
                  text="Max was very responsive and made the borrowing process a
                  breeze."
                />
              </div>
              <div className="col-md-4">
                <Review
                  className="home-review-item"
                  name="Annie"
                  city="Arlington, VA."
                  img="https://firebasestorage.googleapis.com/v0/b/yellow-3952c.appspot.com/o/users%2Favatar-1530636357462-0eebdd9cdd5e.jpeg?alt=media&token=2e9fd310-afdd-417e-93b1-427bcef0a636"
                  text="Rachel was communicative and easy to deal with. Perfect
                  for my day in DC."
                />
              </div>
              <div className="col-md-4">
                <Review
                  className="home-review-item"
                  name="Steven"
                  city="Washington, D.C."
                  img="https://firebasestorage.googleapis.com/v0/b/yellow-3952c.appspot.com/o/users%2Favatar-1526866428207-a2ffb7d82586.jpeg?alt=media&token=66059d43-d455-456a-99fc-e733c66c7bd1"
                  text="This was great for my first borrowing experience - would
                  do again."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="camera-section">
        <div className="container">
          <div className="row">
            <img src={camera_bg} id="camera-bg" />
            <h1 style={{ marginBottom: 20 }}>Capture the perfect shot</h1>
            <FeaturedCameraItems />
          </div>
        </div>
      </section>

      {/* <div
        style={{
          postion: 'relative',
          maxWidth: `100vw`,
          overflowX: 'hidden',
          height: 1,
        }}
      >
        <svg
          className="home-cloud home-cloud-2"
          xmlns="http://www.w3.org/2000/svg"
          width="1360"
          height="958"
          viewBox="0 0 1360 958"
        >
          <path
            fillRule="evenodd"
            d="M856.032,43.233 C763.56,51.354 755.995,47.037 702.004,24.653 C665.707,8.809 625.635,0 583.5,0 C457.462,0 349.808,78.649 306.897,189.541 C272.98,271.849 260.919,283.503 181.846,316.876 C75.359,358.829 0,462.613 0,584 C0,742.506 128.494,871 287,871 C328.535,871 368.001,862.164 403.643,846.289 C491.057,809.571 522.617,802.872 651.485,883.891 C723.395,930.758 809.265,958 901.5,958 C1154.723,958 1360,752.723 1360,499.5 C1360,246.277 1154.723,41 901.5,41 C886.155,41 870.989,41.76 856.032,43.233 Z"
            opacity=".2"
          />
        </svg>
      </div> */}
      <section className="page-section" id="community-section">
        <div className="container">
          <div className="row community-row">
            <h1>Our community of trusted users</h1>
            <div className="col-md-4 home-col">
              <h3>Verification</h3>
              <p>
                Renters and Lenders are vetted through our ID verification
                process to create a trusted community.
              </p>
            </div>
            <div className="col-md-4 home-col">
              <h3>Reviews</h3>
              <p>
                Approvals and review are an easy way to learn from others and
                share your experience.
              </p>
            </div>
            <div className="col-md-4 home-col">
              <h3>Meetups</h3>
              <p>
                Look for opportunities to meet and hang out with local members
                in person at sponsored meetups.
              </p>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 5,
                position: 'relative',
                marginTop: 90,
              }}
            >
              <Link to="/search">
                <button className="big-fat-btn">Start Browsing</button>
              </Link>
            </div>
          </div>
        </div>
        <img src={halfBike_bg} className="bike-background-footer" />
      </section>
    </div>
  )
}

export default ({ data, location }) => {
  useNavbarConfig({ overlay: true, showSearchBar: false })
  return (
    <Layout location={location}>
      <SEO />
      <IndexPage data={data} />
    </Layout>
  )
}
