import React from 'react'
import Link from 'gatsby-link'
import { graphql, StaticQuery } from 'gatsby'

import './style.scss'
import CustomSlider from 'components/shared/Slider/CustomSlider'
import ItemSearchDisplay from 'components/shared/Item/ItemSearchDisplay'

const FavoriteSlider = props => {
  return (
    <CustomSlider items={props.data.allFleet}>
      {props.data.allFleet.edges.map(({ node: product }) => (
        <div className="slide" key={product.id}>
          <div className="slide-container">
            <ItemSearchDisplay
              key={product.id}
              product={product}
              showRent={false}
            />
          </div>
        </div>
      ))}
    </CustomSlider>
  )
}

export default props => (
  <div className="container">
    <h3 style={{ color: '#222', display: 'inline-block', marginRight: 10 }}>
      Featured
    </h3>
    <span>
      <svg
        aria-hidden="true"
        data-prefix="fal"
        data-icon="stars"
        className="stars-icon"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M364.3 266.9l-104.9-15.3-46.9-95.3c-4.2-8.5-12.4-12.8-20.6-12.8-8.2 0-16.3 4.2-20.5 12.8l-46.9 95.3-104.8 15.3C.9 269.6-6.6 292.9 7 306.2l75.9 74.1-18 104.7c-2.6 15 9.3 27 22.6 27 3.5 0 7.2-.8 10.7-2.7l93.8-49.4 93.8 49.4c3.5 1.8 7.1 2.7 10.6 2.7 13.3 0 25.2-12 22.7-26.9l-17.9-104.7 75.9-74.1c13.6-13.4 6-36.6-12.8-39.4zm-85.5 90.5l-12 11.8 2.8 16.6 14.8 86.6-77.6-40.9-14.9-7.9-14.9 7.9-77.6 40.9 14.8-86.6 2.8-16.6-12-11.8L42.3 296l86.8-12.7 16.6-2.4 7.4-15.1L192 187l38.8 78.8 7.4 15.1 16.6 2.4 86.8 12.7-62.8 61.4zm-30-247.6l48.6 24.3 24.3 48.6c2.7 5.4 8.2 8.9 14.3 8.9s11.6-3.4 14.3-8.9l24.3-48.6 48.6-24.3c5.4-2.7 8.8-8.3 8.8-14.3s-3.4-11.6-8.8-14.3l-48.6-24.3-24.3-48.8c-5.4-10.9-23.2-10.9-28.6 0l-24.3 48.6L248.8 81c-5.4 2.7-8.8 8.3-8.8 14.3s3.4 11.7 8.8 14.5zM316.5 83c3.1-1.5 5.6-4.1 7.2-7.2L336 51.1l12.3 24.7c1.6 3.1 4.1 5.6 7.2 7.2l24.7 12.4-24.7 12.4c-3.1 1.5-5.6 4.1-7.2 7.2L336 139.7 323.7 115c-1.6-3.1-4.1-5.6-7.2-7.2l-24.7-12.4L316.5 83zM480 191.6l-16-32-16 32-32 16 32 16 16 32 16-32 32-16-32-16z"
        />
      </svg>
    </span>
    <StaticQuery
      query={graphql`
        query {
          allFleet(
            limit: 13
            filter: { approved: { eq: true }, featured: { eq: true } }
          ) {
            totalCount
            edges {
              node {
                id
                description
                pictures
                name
                type
                city
                state
                price_d
                user {
                  id
                  firstName
                  lastName
                  picture
                  lastName
                  rating_count
                  avg_rating
                }
              }
            }
          }
        }
      `}
      render={data => <FavoriteSlider data={data} {...props} />}
    />
  </div>
)
