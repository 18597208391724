import React from 'react'
import Link from 'gatsby-link'
import { graphql, StaticQuery } from 'gatsby'

import './style.scss'
import CustomSlider from 'components/shared/Slider/CustomSlider'
import ItemSearchDisplay from 'components/shared/Item/ItemSearchDisplay'

const FavoriteSlider = props => {
  return (
    <CustomSlider items={props.data.allFleet}>
      {props.data.allFleet.edges.map(({ node: product }) => (
        <div className="slide" key={product.id}>
          <div className="slide-container">
            <ItemSearchDisplay
              key={product.id}
              product={product}
              showRent={false}
            />
          </div>
        </div>
      ))}
    </CustomSlider>
  )
}

export default props => (
  <div className="container">
    <h3 style={{ color: '#222', display: 'inline-block', marginRight: 10 }}>
      Camera Equipment Rentals
    </h3>
    <span />
    <StaticQuery
      query={graphql`
        query {
          allFleet(
            filter: {
              approved: { eq: true }
              featured: { eq: true }
              type: { eq: "camera-equipment" }
            }
          ) {
            totalCount
            edges {
              node {
                id
                description
                pictures
                name
                type
                city
                state
                price_d
                user {
                  id
                  firstName
                  lastName
                  picture
                  lastName
                  rating_count
                  avg_rating
                }
              }
            }
          }
        }
      `}
      render={data => <FavoriteSlider data={data} {...props} />}
    />
  </div>
)
