import React, { useEffect, useRef } from 'react'
import 'intersection-observer'

/**
 * A component that helps with detectign when a sticky coomponent is stickied
 * Somewhat based on https://developers.google.com/web/updates/2017/09/sticky-headers
 * @param {{onStickyChange:Function}} props
 */
export default function StickySentinal(props) {
  const ref = useRef()
  const { onStickyChange } = props
  useEffect(() => {
    if (!ref.current) {
      return
    }
    const observer = new IntersectionObserver(records => {
      for (const record of records) {
        const targetInfo = record.boundingClientRect
        const rootBoundsInfo = record.rootBounds

        // Started sticking.
        if (targetInfo.bottom < rootBoundsInfo.top) {
          typeof onStickyChange === 'function' && onStickyChange(true)
        }

        // Stopped sticking.
        if (
          targetInfo.bottom >= rootBoundsInfo.top &&
          targetInfo.bottom < rootBoundsInfo.bottom
        ) {
          typeof onStickyChange === 'function' && onStickyChange(false)
        }
      }
    }, {})
    observer.observe(ref.current)
    return () => observer.unobserve(ref.current) && observer.disconnect()
  }, [ref, onStickyChange])

  return (
    <div
      ref={ref}
      style={{
        width: '100vw',
        height: 0,
      }}
    />
  )
}
