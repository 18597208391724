import React from 'react'
import Link from 'gatsby-link'

import Slider from 'react-slick'
import './CategorySlider.scss'
import * as categories from 'constants/categories'
import { searchStore } from 'SearchStore'

const slug = word => {
  return word
    .toLowerCase()
    .replace(' ', '-')
    .replace('& ', '')
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="categories-slider-arrow--next categories-slider-arrow"
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="slider-arrow"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"
        />
      </svg>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="categories-slider-arrow--prev categories-slider-arrow"
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="slider-arrow"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z"
        />
      </svg>
    </div>
  )
}

export default class CategorySlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchState: {
        geoSearch: {
          lat: 38.894377,
          lng: -77.040798,
          radius: 50,
          name: '',
        },
        query: '',
      },
      url: '',
    }
  }

  createURL = state => {
    const url = qs.stringify(state)
    return `?${url}`
  }

  render() {
    const settings = {
      infinite: false,
      speed: 500,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      variableWidth: true,
      slidesToShow: 6,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <div className="categories-wrapper" {...this.props}>
        <div className="categories-slider-container">
          <Slider className="categories-slider" {...settings}>
            {Object.keys(categories.ITEM_CATEGORIES).map((category, index) => (
              <div
                key={`category-${index}`}
                className={`categories-slider-item category-${index}`}
              >
                <Link
                  onClick={() => (
                    searchStore.reset(),
                    searchStore.setCategory(`type:${slug(category)}`)
                  )}
                  to={`/search?include=category`}
                >
                  {category}
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  }
}
