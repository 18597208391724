import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  // faChevronCircleDown as Indicator
  faChevronDown as Indicator,
} from '@fortawesome/free-solid-svg-icons'

import styles from './ScrollIndicator.module.scss'
/**
 * Displays a chevron
 */
export default function ScrollIndicator(props) {
  const {
    scrollTarget = null,
    scrollTo = 0,
    children,
    className = '',
    block = 'start',
    ...containerProps
  } = props

  const scrollIt = e => {
    if (typeof scrollTo === 'string') {
      let target = document.querySelector(scrollTo)
      if (target instanceof HTMLElement) {
        target.scrollIntoView({ behavior: 'smooth', block })
      }
      return
    }
    let scrollee = scrollTarget || e.currentTarget.parentElement
    if (typeof scrollTarget === 'string') {
      scrollee = document.querySelector(scrollTarget)
    }
    scrollee = scrollee.current || scrollee
    scrollee.scrollTo({ top: scrollTo })
  }

  return (
    <div
      onClick={scrollIt}
      className={[styles.container, className].join(' ')}
      {...containerProps}
    >
      {children || (
        <Icon icon={Indicator} size={'2x'} color={'rgba(0,0,0,0.66)'} />
      )}
    </div>
  )
}
